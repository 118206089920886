import { Typography } from "@mui/material"
import { Fade } from "react-reveal"



export const HomePage = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#fbddc0',
            flexDirection: 'column'
        }}>
            <Fade>
                <h1 style={{textTransform: 'uppercase', letterSpacing: '2px'}}>Forty Acres Capital</h1>
                <Typography>Coming Soon</Typography>
            </Fade>
        </div>
    )
}